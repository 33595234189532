export const LOADING = "LOADING";
export const SEO = "SEO";
//user constant
export const LOGIN = "LOGIN";
export const REGISTER = "REGISTER";
export const LOGIN_WITH_OTP = "LOGIN_WITH_OTP";

//student Constant
export const STUDENT_REGISTER = "STUDENT_REGISTER";
export const STUDENT_VERFIY_OTP = "STUDENT_VERFIY_OTP";
export const STUDENT_CHECK_EMAIL_EXIST = "STUDENT_CHECK_EMAIL_EXIST";
export const STUDENT_CHECK_MOBILE_EXIST = "STUDENT_CHECK_MOBILE_EXIST";
export const STUDENT_UPDATE = "STUDENT_UPDATE";
export const GET_STUDENT_BY_ID = "GET_STUDENT_BY_ID";
export const STUDENT_ATTACHMENT = "STUDENT_ATTACHMENT";
export const GET_ATTACHMENT = "GET_ATTACHMENT";
export const DELETE_ATTACHMENT = "DELETE_ATTACHMENT";
export const EMAIL_VERIFICATION = "EMAIL_VERIFICATION";
export const VERIFICATION_OF_EMAIL = "VERIFICATION_OF_EMAIL";
export const SHORTLIST_UNIVERSITY = "SHORTLIST_UNIVERSITY";
export const SHORTLIST_COURSES = "SHORTLIST_COURSES";
export const STUDENT_DISCUSSION = "STUDENT_DISCUSSION";
export const STUDENT_PAYMENT_HISTORY = "STUDENT_PAYMENT_HISTORY";
export const UNI_REVIEW = "UNI_REVIEW";
export const SERVICE_LIST = "SERVICE_LIST";
export const MY_APPOINTMENT = "MY_APPOINTMENT";
export const COACH_COUNSELLOR_FOR_PROFILE = "COACH_COUNSELLOR_FOR_PROFILE";
export const STUDENT_REVIEW = "STUDENT_REVIEW";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const LOGIN_WITH_PASSWORD = "LOGIN_WITH_PASSWORD";

//discussion Constant
export const GET_DISCUSSION_LIST = "GET_DISCUSSION_LIST";
export const GET_DISCUSSION_BY_ID = "GET_DISCUSSION_BY_ID";
export const GET_DISCUSSION = "GET_DISCUSSION";
export const GET_DISCUSSION_COMMENT_LIST = "GET_DISCUSSION_COMMENT_LIST";
export const DISCUSSION_COMMENT_ADD = "DISCUSSION_COMMENT_ADD";
export const GET_DISCUSSION_CATEGORY_LIST = "GET_DISCUSSION_CATEGORY_LIST";
export const TRENDING_DISCUSSION = "TRENDING_DISCUSSION";
export const RELATED_DISCUSSION = "RELATED_DISCUSSION";
export const DISCUSSION_LIKE_UNLIKE = "DISCUSSION_LIKE_UNLIKE";
export const DISCUSSION_COMMENT_LIKE_UNLIKE = "DISCUSSION_COMMENT_LIKE_UNLIKE";
export const ADD_DISCUSSION = "ADD_DISCUSSION";

//university Constant
export const GET_UNIVERSITIES_LIST = "GET_UNIVERSITIES_LIST";
export const GET_UNIVERSITIES_BY_ID = "GET_UNIVERSITIES_BY_ID";
export const GET_UNIVERSITIES = "GET_UNIVERSITIES";
export const GET_UNIVERSITIES_HOME = "GET_UNIVERSITIES_HOME";
export const GET_ALL_UNIVERSITIES_COMMENT = "GET_ALL_UNIVERSITIES_COMMENT";
export const ADD_UNIVERSITIES_COMMENT = "ADD_UNIVERSITIES_COMMENT";
export const GET_RANKING_UNIVERSITIES = "GET_RANKING_UNIVERSITIES";
export const GET_UNIVERSITIES_BY_SLUG = "GET_UNIVERSITIES_BY_SLUG";
export const TRENDING_UNIVERSITIES = "TRENDING_UNIVERSITIES";
export const GET_UNIVERSITIES_COUNT = "GET_UNIVERSITIES_COUNT";
export const UNIVERSITY_LIKE_DISLIKE = "UNIVERSITY_LIKE_DISLIKE";
export const UNIVERSITY_LIKE = "UNIVERSITY_LIKE";
export const GET_AD_BANNER = "GET_AD_BANNER";

//faq Constant
export const GET_FAQ_CATEGORY_LIST = "GET_FAQ_CATEGORY_LIST";
export const GET_FAQ_BY_ID = "GET_FAQ_BY_ID";
export const GET_FAQ_LIST = "GET_FAQ_LIST";

//country Constant
export const GET_ALL_COUNTRY = "GET_ALL_COUNTRY";
export const GET_COUNTRY_BY_SLUG = "GET_COUNTRY_BY_SLUG";

//country Constant
export const GET_ALL_state = "GET_ALL_state";

//course Constant
export const GET_ALL_UNI_COURSE = "GET_ALL_UNI_COURSE";
export const GET_ALL_COURSE = "GET_ALL_COURSE";
export const COUNTRY_COURSE = "COUNTRY_COURSE";
export const COUNTRY_EXAM = "COUNTRY_EXAM";
export const GET_COURSE = "GET_COURSE";
export const COURSE_DETAIL = "COURSE_DETAIL";
export const GET_SIMILAR_COURSE = "GET_SIMILAR_COURSE";

//testimonial Constant
export const GET_TESTIMONIAL_lIST = "GET_TESTIMONIAL_lIST";

//package Constant
export const GET_PACKAGE_lIST = "GET_PACKAGE_lIST";

//counsellor Constant
export const GET_ALL_COUNSELLOR = "GET_ALL_COUNSELLOR";
export const GET_COUNSELLOR_BY_ID = "GET_COUNSELLOR_BY_ID";
export const GET_COUNSELLOR_INFO = "GET_COUNSELLOR_INFO";
export const GET_COACH_AND_COUNSELLOR = "GET_COACH_AND_COUNSELLOR";
export const GET_COUNSELLOR_BY_SLUG = "GET_COUNSELLOR_BY_SLUG";
export const BOOK_APPOINTMENT = "BOOK_APPOINTMENT";

// getCounsellorBySlug

//coach Constant
export const GET_ALL_COACH = "GET_ALL_COACH";
export const GET_COACH_BY_ID = "GET_COACH_BY_ID";
export const GET_COACH_INFO = "GET_COACH_INFO";
export const GET_COACH_BY_SLUG = "GET_COACH_BY_SLUG";

//office  Constant
export const GET_OFFICE_LIST = "GET_OFFICE_LIST";
export const GET_OFFICE_BY_ID = "GET_OFFICE_BY_ID";
export const GET_OFFICE_BY_SLUG = "GET_OFFICE_BY_SLUG";

//articale Constant
export const GET_ARTICALE_LIST = "GET_ARTICALE_LIST";
export const GET_ARTICALE_BY_ID = "GET_ARTICALE_BY_ID";
export const GET_REVIEW_LIST = "GET_REVIEW_LIST";

//city Constant
export const GET_ALL_CITY = "GET_ALL_CITY";
export const GET_CITY_BY_ID = "GET_CITY_BY_ID";

//stream Constant
export const GET_STREAM_LIST = "GET_STREAM_LIST";

// apply now Constant
export const APPLICANTADD = "APPLICANTADD";
export const GET_ALL_APLICANT = "GET_ALL_APLICANT";
export const APPLY_NOW = "APPLY_NOW";
export const GET_APPLICATION_LIST = "GET_APPLICATION_LIST";

// blog Constant
export const GET_BLOG_LIST = "GET_BLOG_LIST";
export const GET_BLOG_BY_ID = "GET_BLOG_BY_ID";
export const GET_BLOG_CATEGORY = "GET_BLOG_CATEGORY";
export const BLOG_UPDATE_LIKE_COMMENT = "BLOG_UPDATE_LIKE_COMMENT";
export const BLOG_LIKE_UNLIKE = "BLOG_LIKE_UNLIKE";
export const GET_USER_ACTION_FOR_BLOG_DETAILS =
  "GET_USER_ACTION_FOR_BLOG_DETAILS";
export const BLOG_BY_SLUG = "BLOG_BY_SLUG";
export const BLOG_COMMENT_ADD = "BLOG_COMMENT_ADD";
export const BLOG_COMMENT_FIND_ALL = "BLOG_COMMENT_FIND_ALL";
export const V_BLOG = "V_BLOG";

// scholership and offer Constant
export const GET_ALL_COUPON = "GET_ALL_COUPON";
export const SCHOLARSHIP_GET_ALL_DETAIL = "SCHOLARSHIP_GET_ALL_DETAIL";
export const FIND_BY_SCHOLAR_SLUG = "FIND_BY_SCHOLAR_SLUG";
export const GET_OFFER_AND_SCHOLAR = "GET_OFFER_AND_SCHOLAR";
export const GET_APPLIED_SCHOLAR = "GET_APPLIED_SCHOLAR";
/**
 * Settings
 */
export const GET_SETTING = "GET_SETTING";

/**
 * Contact-Us
 */
export const GET_IN_TOUCH = "GET_IN_TOUCH";
export const SERVICE_REQUEST = "SERVICE_REQUEST";
export const GET_ALL_QUERY = "GET_ALL_QUERY";

/**
 * Career
 */
export const GET_JOB_LIST = "GET_JOB_LIST";
export const APPLY_FOR_JOB = "APPLY_FOR_JOB";

/**
 * Upload File
 */
export const UPLOAD_FILE = "UPLOAD_FILE";

/**
 * Country Blog & Category
 */
export const COUNTRY_BLOG = "COUNTRY_BLOG";
export const COUNTRY_CATEGORY = "COUNTRY_CATEGORY";
export const APPLIED_COURSE = "APPLIED_COURSE";
