import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { getToken } from "../../Utils/Auth/Token";
import { URL } from "./url";
let token = getToken();
let Api: AxiosInstance = axios.create({
  baseURL: URL.API_BASE_URL,
  headers: {
    token: token ? token : "",
  },
});
Api.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    if (error?.response?.status) {
      /* window.location.href = "/page-not-found" */
    }
    return Promise.reject(error);
  }
);
export default Api;
