import axios from "axios";
import { toast } from "react-toastify";
import { URL } from "../common/url";
import api from "./../common/api";
import {
  LOADING,
  GET_OFFICE_LIST,
  GET_OFFICE_BY_ID,
  GET_IN_TOUCH,
  SERVICE_REQUEST,
  GET_ALL_QUERY,
  GET_OFFICE_BY_SLUG,
} from "../common/constant";

const loading = (data: any) => {
  return { type: LOADING, payload: data };
};
const getofficelistAction = (data: any) => {
  return { type: GET_OFFICE_LIST, payload: data };
};
const getofficebyidAction = (data: any) => {
  return { type: GET_OFFICE_BY_ID, payload: data };
};
const addQueryAction = (data: any) => {
  return { type: GET_IN_TOUCH, payload: data };
};
const submitServiceQueryAction = (data: any) => {
  return { type: SERVICE_REQUEST, payload: data };
};

const addQueryAllAction = (data: any) => {
  return { type: GET_ALL_QUERY, payload: data };
};

const getofficebyslugAction = (data: any) => {
  return { type: GET_OFFICE_BY_SLUG, payload: data };
};

export const getofficelist = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(loading(true));
      const response: any = await api.get(`${URL.getofficelist}`);
      dispatch(getofficelistAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getofficebyid = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(loading(true));
      const response: any = await api.get(`${URL.getofficebyid}?id=${data}`);
      dispatch(getofficebyidAction(response?.data?.data));
      dispatch(loading(false));
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getInTouch = (data: any, history: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(loading(true));
      let dt = { ...data };
      if (dt?.thank) {
        delete dt.thank;
      }
      const response: any = await api.post(`${URL.addGetInTouch}`, dt);
      dispatch(addQueryAction(response?.data?.data));
      console.log(data?.thank, response.data);
      if (response?.data && data?.thank) {
        history.push("/app/thank-you");
      } else {
        history.push("/thank-you");
      }
      dispatch(loading(false));
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const submitQuery = (data: any, history: any) => {
  return async (dispatch: any) => {
    try {
      // dispatch(loading(true));
      let dt = { ...data };
      if (dt?.thank) {
        delete dt.thank;
      }
      const response: any = await api.post(`${URL.serviceRequest}`, dt);
      dispatch(submitServiceQueryAction(response?.data?.data));
      if (response?.data?.status) {
        setTimeout(function () {
          if (data?.thank) {
            history.push("/app/thank-you");
          } else {
            history.push("/thank-you");
          }
        }, 1000);
      }
      // dispatch(loading(false));
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getQueryAll = (data: any, history: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(loading(true));
      let dt = { ...data };
      if (dt?.thank) {
        delete dt.thank;
      }
      const response: any = await api.post(`${URL.addQueryAll}`, dt, {
        headers: {
          token:
            "60f291aa46ea a46ea447060f291aa46ea447019d83b 19d83ba30be508e4",
        },
      });
      dispatch(addQueryAllAction(response?.data?.data));
      console.log(data?.thank, response.data);
      if (response?.data && data?.thank) {
        history.push("/app/thank-you");
      } else {
        history.push("/thank-you");
      }
      dispatch(loading(false));
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getofficebyslug = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(loading(true));
      const response: any = await api.get(
        `${URL.getofficebyslug}?slug=${data}`
      );
      console.log(response, "response");

      dispatch(getofficebyslugAction(response?.data));
      dispatch(loading(false));
    } catch (error: any) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
