import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { testimonialAction } from "../../redux/common/action";
import Slider from "react-slick";
import StarRatings from "react-star-ratings";
import { URL } from "../../redux/common/url";
import ReactPlayer from "react-player";

function TestimonialUpdate() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(true);
  const baseurl = URL.API_BASE_URL;

  const testimonial: any = useSelector((state: any) =>
    state.testimonial.testimonialList ? state.testimonial.testimonialList : []
  );

  useEffect(() => {
    dispatch(
      testimonialAction.gettestimoniallist({ is_visible: 1, status: 1 })
    );
    return () => {};
  }, []);

  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <section className="categories section greybg h-servives" id="services">
        <div className="container">
          <div className="headingmains text-center">
            <h2 className="titlewithline right">
              Testimonial
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="43.032"
                  height="35.894"
                  viewBox="0 0 43.032 35.894"
                >
                  <path
                    id="mortarboard"
                    d="M42.194,52.064a1.261,1.261,0,0,0,0-2.377L21.936,42.541a1.26,1.26,0,0,0-.839,0L.841,49.688a1.261,1.261,0,0,0,0,2.377l8.024,2.853v8.224c0,1.357,1.416,2.505,4.209,3.411a28.187,28.187,0,0,0,8.445,1.225,28.187,28.187,0,0,0,8.445-1.225c2.793-.906,4.209-2.054,4.209-3.411V54.917l3.313-1.178v11.4a3.792,3.792,0,0,0,0,7.153V77.1A1.261,1.261,0,1,0,40,77.1V72.287a3.792,3.792,0,0,0,0-7.153V52.843Zm-3.45,17.918a1.271,1.271,0,1,1,1.271-1.271A1.273,1.273,0,0,1,38.744,69.982ZM21.516,45.067,38,50.881,21.516,56.739,5.037,50.881ZM31.649,62.951a7.787,7.787,0,0,1-3.059,1.384,26.581,26.581,0,0,1-7.073.921,26.582,26.582,0,0,1-7.073-.921,7.786,7.786,0,0,1-3.059-1.384V55.813l9.71,3.452a1.26,1.26,0,0,0,.845,0l9.71-3.452v7.137Z"
                    transform="translate(0 -42.469)"
                    fill="#fbb415"
                  />
                </svg>
              </span>
            </h2>
            <p className="text-center pl-2 pr-2">
              Our students get the fabulous chance to fulfill their dreams by
              the support of the admissify. Read about their thoughts over here.
            </p>
          </div>

          <section className="">
            <div className="ser-box col-12">
              <div className="">
                <Slider {...settings}>
                  {testimonial &&
                    testimonial?.map((data: any, i: any) => {
                      return (
                        <div key={i}>
                          <div className="col-md-12 col-sm-12 col-xs-12 item">
                            <div className="team_member">
                              {/* <figure className="effect-julia">

                              {data?.profile_image !== null ?
                                <img
                                  className="scale"
                                  src={baseurl + data?.profile_image}
                                  alt="team"
                                /> :
                                <img
                                  className="scale"
                                  src="/assets/img/noImage.jpeg"
                                  alt="team"
                                />
                              }
                            </figure> */}
                              <div className="member_name">
                                {data?.testimonial_type == 2 &&
                                data?.video_url ? (
                                  <ReactPlayer
                                    controls
                                    url={data?.video_url}
                                    width="100%"
                                    height="352px"
                                    light={
                                      <img
                                        src={baseurl + data?.profile_image}
                                        alt="cover"
                                        style={{
                                          width: "100%",
                                          height: "352px",
                                          borderRadius: "20px",
                                        }}
                                      />
                                    }
                                  />
                                ) : (
                                  <p className="auto_scroll">{data?.content}</p>
                                )}
                                <div className="testi-footer">
                                  <span></span>
                                  <div className="name-ini-sec">
                                    <h5>{data?.name}</h5>
                                    <div className="uniname-testi">
                                      <p>carnegie mellon university</p>
                                    </div>
                                  </div>
                                </div>

                                {/*
                              <div className="feat-inn">
                                <ul>
                                  <StarRatings
                                    rating={
                                      parseFloat(data?.rating)
                                        ? parseFloat(data?.rating)
                                        : 0
                                    }
                                    starRatedColor="#fcbd30"
                                    starDimension="15px"
                                    starSpacing="0px"
                                  />
                                </ul>
                              </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </Slider>
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  );
}

export default TestimonialUpdate;
